'use strict'

###*
 # @ngdoc service
 # @name ReportSourceManager.factory:ReportTransportManager

 # @description

###
angular
  .module 'mundoReporting'
  .factory 'ReportTransportManager', [
    'MundoDefaultManager'
    '$q'
    'Restangular'
    (
      MundoDefaultManager
      $q
      Restangular
    ) ->
      ReportTransportManagerBase = new MundoDefaultManager()
      ReportTransportManagerBase.setUrl('reports/transports')

      ReportTransportManagerBase.one = (code) ->
        @transport.one('reports/transports', code).get()

      ReportTransportManagerBase.getListCustom = ->
        $q (resolve, reject) =>
          results = []

          Restangular.all(@conf.url).customGET("").then (data) ->
            for k, v of data.results
              results.push v

            resolve results
          , () ->
            reject()

      ReportTransportManagerBase.getList = ->
        @getListCustom()

      ReportTransportManagerBase
  ]
